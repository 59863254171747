import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import add from "date-fns/add";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useSnackbar } from "notistack";


import MainFrame from "./Mainframe";
import ConfigContext from "./ConfigContext";

import VerifyLoggedInEffect from "./VerifyLoggedInEffect";

function SendInvitation() {
    const history = useHistory();
    const config = useContext(ConfigContext);
    const { enqueueSnackbar } = useSnackbar();

    const [purpose, setPurpose] = useState("");
    const [validity, setValidity] = useState(new Date());
    const [name, setName] = useState("");
    // const [email, setEmail] = useState("");
    const [purposeList, setPurposeList] = useState([]);
    const [isBtnDisabled, setBtnDisabled] = useState(false);

    const verifyInvitation =  obj => {
        for (const key in obj) {
            if (!obj[key]) return { ok: false, errMsg: "All fields are mandatory" };
        }

        return { ok: true };
    };

    const createInvitation = async () => {
        setBtnDisabled(true);
        let postBody = { validity, purpose, name };

        const { ok, errMsg } = verifyInvitation(postBody);

        if (!ok) {
            enqueueSnackbar(errMsg, {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            });

            setBtnDisabled(false);
            return;
        }

        let response = await fetch(`${config.url}/invite`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(postBody)
        });

        if (response.status === 201) {
            let responseMsg = await response.json();
            history.push(`/invitation/view/${responseMsg.createdBy}/${responseMsg._id}`);
        }
    };

    useEffect(() => {
        fetch(`${config.url}/user/visit/purposes`, {
            method: "GET",
            credentials: "include"
        })
        .then(resp => resp.json())
        .then(data => setPurposeList(data));
    }, [config.url]);

    return (
        <MainFrame>
            <VerifyLoggedInEffect />

            <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="purpose-select-label">Purpose</InputLabel>
                    <Select labelId="purpose-select-label" label="Purpose" value={purpose} onChange={e => setPurpose(e.target.value)}>
                        { purposeList.map(p => <MenuItem value={`${p.category},${p.type}`} key={`${p.category},${p.type}`}>{p.type}</MenuItem>) }
                    </Select>
                </FormControl>
            </Grid>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12}>
                    <KeyboardDatePicker 
                        label="Validity Date"
                        value={validity}
                        format="dd-MM-yyyy"
                        disablePast="true"
                        maxDate={add(new Date(), { months: 1 })}
                        inputVariant="outlined"
                        fullWidth
                        onChange={setValidity}
                    />
                </Grid>
            </MuiPickersUtilsProvider>

            <Grid item xs={12}>
                <TextField label="Name" value={name} variant="outlined" fullWidth onChange={e => setName(e.target.value.toUpperCase())} />
            </Grid>
            {/* <Grid item xs={12}>
                <TextField label="Email Address" value={email} type="email" variant="outlined" fullWidth onChange={e => setEmail(e.target.value)} />
            </Grid> */}
            <Grid item xs={12}>
                <Button variant="contained" color="primary" fullWidth onClick={createInvitation} disabled={isBtnDisabled}>Send</Button>
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" color="secondary" fullWidth onClick={ () => history.push("/home") } disabled={isBtnDisabled}>Cancel</Button>
            </Grid>
        </MainFrame>
    );
}

export default SendInvitation;